<template>
  <div class="gua-main">
    <div class="content">
      <div v-for="(form, i) in guaList" class="block-item" :key="i">
        <div class="tit">
          <span>担保人({{ i + 1 }})</span>
          <svg
            v-if="!form.old"
            @click="delItem(i)"
            class="icon"
            viewBox="0 0 1024 1024"
            width="20"
            height="20"
          >
            <path
              d="M202.666667 256h-42.666667a32 32 0 0 1 0-64h704a32 32 0 0 1 0 64H266.666667v565.333333a53.333333 53.333333 0 0 0 53.333333 53.333334h384a53.333333 53.333333 0 0 0 53.333333-53.333334V352a32 32 0 0 1 64 0v469.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H320c-64.8 0-117.333333-52.533333-117.333333-117.333334V256z m224-106.666667a32 32 0 0 1 0-64h170.666666a32 32 0 0 1 0 64H426.666667z m-32 288a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z m170.666666 0a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z"
              fill="#666"
            ></path>
          </svg>
        </div>
        <Form
          :ref="`form-${form.old ? form.id : form.key}`"
          :form-name="`担保人(${i + 1})`"
          :defaultForm.sync="form"
          :fields="fields"
          :disabled="form.old && mode == 1"
        >
          <van-field
            :slot="`field-custom-name`"
            v-model.trim="form.name"
            label="担保人姓名"
            placeholder="上传识别"
            :rules="required"
            required
            :disabled="form.old && mode == 1"
            input-align="right"
          >
            <template #right-icon>
              <ImgIcon
                :size="28"
                color="#4191f9"
                @click="form.old && mode == 1 ? void 0 : openIdcarOcr(i)"
              />
            </template>
          </van-field>
        </Form>
      </div>
      <div v-if="!guaList.length" class="none-data">暂无担保人</div>
    </div>

    <div class="btns">
      <van-button size="small" @click="$router.go(-1)">返回</van-button>
      <van-button size="small" @click="addItem">新增</van-button>
      <van-button type="info" size="small" @click="saveFn">保存</van-button>
    </div>
    <IdentityOCR
      v-model="ocrShow"
      :prePlatOrderNo="prePlatOrderNo"
      ocr-type="2"
      @success="ocrSuccess"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { guarantorAdd, guarantorList } from "@/api/apply";
export default {
  components: {
    Form: () => import("@/components/Form/Index"),
    ImgIcon: () => import("@/components/ImgIcon.vue"),
    IdentityOCR: () => import("../components/IdentityOCR.vue")
  },
  computed: {
    fields: {
      get() {
        return [
          {
            type: "custom",
            label: "担保人姓名",
            placeholder: "上传识别",
            required: true,
            key: "name",
            validator: (v, pre) => {
              const reg = /^(?:[\u4e00-\u9fa5·]{2,20})$/;
              if (v && !reg.test(v)) throw `${pre}请输入正确的姓名`;
              else return true;
            }
          },
          {
            type: "text",
            label: "担保人身份证号",
            required: true,
            placeholder: "上传识别",
            disabled: true,
            labelWidth: "7em",
            key: "idCardNo",
            validator: (v, pre) => {
              const reg =
                /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
              if (v && !reg.test(v)) throw `${pre}请输入正确的身份证号`;
              else return true;
            }
          },
          {
            label: "有效期",
            key: "expiryDate",
            required: true,
            disabled: true,
            placeholder: "上传识别",
            type: "text"
          },
          {
            type: "number",
            label: "担保人手机号",
            required: true,
            labelWidth: "7.2em",
            key: "mobile",
            validator: (v, pre) => {
              const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
              if (v && !reg.test(v)) throw `${pre}请输入正确的手机号`;
              else return true;
            }
          }
        ];
      },
      set() {
        this.$forceUpdate();
      }
    }
  },
  data() {
    return {
      // mode = 1，只能新增; mode = 2 只能编辑和新增; mode = 3
      mode: 1,
      required: [
        {
          required: true
        }
      ],
      currentIndex: 0,
      applyIndexCertificateNo: "",
      guaList: [],
      prePlatOrderNo: "",
      ocrShow: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapGetters(["getUserOrderInfo", "getUserInfo"]),
    init() {
      const { mode } = this.$route.query;
      this.mode = mode || 1;
      const data = this.getUserOrderInfo();
      this.prePlatOrderNo = data?.prePlatOrderNo || "";
      this.applyIndexCertificateNo = window.sessionStorage.getItem(
        "_apply_index_certificateNo"
      );

      const data2 = this.getUserInfo();
      this.applyUserMobile = data2?.customerPhone || data2?.loginMobile;
      this.getGuarantorList();
    },
    async getGuarantorList() {
      try {
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await guarantorList(this.prePlatOrderNo);
        this.guaList = (data || []).map((e) => {
          return {
            ...e,
            old: true
          };
        });
        this.$toast.clear();
      } catch (error) {
        this.$errMsg();
      }
    },
    openIdcarOcr(index) {
      this.currentIndex = index;
      try {
        const prePlatOrderNo = this.prePlatOrderNo;
        if (!prePlatOrderNo) throw "预进件订单错误";
        this.ocrShow = true;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    ocrSuccess(d) {
      this.$set(this.guaList, this.currentIndex, d);
    },
    addItem() {
      const key = `key-${Math.random().toString(32).slice(-8)}`;
      this.guaList.push({
        key: key,
        name: "",
        idCardNo: "",
        expiryDate: "",
        mobile: ""
      });
    },
    delItem(i) {
      this.guaList.splice(i, 1);
    },
    async saveFn() {
      try {
        const newList = this.guaList.filter((e) => !e.old);
        if (!newList.length) throw "请添加至少一个新联系人";
        const unpass = newList.some(
          (e) => e.idCardNo == this.applyIndexCertificateNo
        );
        const unpass2 = newList.some((e) => e.mobile == this.applyUserMobile);
        if (unpass) throw "担保人身份证号与申请人身份证号不能相同";
        if (unpass2) throw "担保人手机号与申请人手机号不能相同";

        // for (let i = 0; i < newList.length; i++) {
        //   const key = newList[i].key;
        //   const formObj = this.$refs[`form-${key}`][0];
        //   await formObj.validate();
        // }

        for (let i = 0; i < this.guaList.length; i++) {
          const key = this.guaList[i].old
            ? this.guaList[i].id
            : this.guaList[i].key;
          const formObj = this.$refs[`form-${key}`][0];
          await formObj.validate();
        }

        const params = {
          prePlatOrderNo: this.prePlatOrderNo,
          guarantorReqVOList: this.guaList
        };
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        await guarantorAdd(params);
        this.$toast.success("添加成功");
        const str = this.$route.query.redirect;
        if (str) {
          const redirect = decodeURIComponent(str);
          this.$router.replace(redirect);
        }
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.gua-main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  .content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 24px;
    padding: 0 24px;
    .block-item {
      border-bottom: 1px solid #dfdfdf;
      .tit {
        line-height: 72px;
        margin-top: 24px;
        font-size: 32px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .btns {
    height: 112px;
    min-height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
    box-shadow: 0 -10px 10px #f6f6f6;
    .van-button {
      width: 30%;
      height: 60%;
    }
  }
}
</style>
